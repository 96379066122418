import React, { memo, useEffect, useRef } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import styles from 'Custom/Finder/index.module.scss';
import FINDER, { FINDER_SCRIPT } from 'Custom/Finder/utils';
const {
  ANCHORS,
  WIDGET_WRAPPER_ID,
  CHECK_POSITION_DELAY,
  SELECTORS,
  WIDGET_OFFSET,
  INITIAL_POSITION,
} = FINDER;

const Finder = ({ className = '', ...props }) => {
  const iframeRef = useRef(null);
  const router = useRouter();

  const scrollToIframe = (target) => {
    const hash = window.location.hash;

    if (!ANCHORS.includes(hash) || !target) {
      return;
    }

    let lastPosition = INITIAL_POSITION;
    let interval = null;

    const startScroll = (position) => {
      const header = document.querySelector(SELECTORS.HEADER);
      const isiFiller = document.querySelector(SELECTORS.ISI_FILLER);
      const offset = Math.max(
        header.getBoundingClientRect().height,
        isiFiller.getBoundingClientRect().height
      );

      window.scrollTo({ top: position - offset });
    };

    const checkPosition = () => {
      const topOffset = window.scrollY || window.pageYOffset;
      const currentPosition =
        target.getBoundingClientRect().top + topOffset - WIDGET_OFFSET;

      if (currentPosition === lastPosition) {
        clearInterval(interval);
        startScroll(currentPosition);
      } else {
        lastPosition = currentPosition;
      }
    };

    interval = setInterval(checkPosition, CHECK_POSITION_DELAY);
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    const target = iframe.querySelector(SELECTORS.WIDGET);

    if (target) {
      scrollToIframe(target);
      return;
    }

    const internalObserver = new MutationObserver(function () {
      const localTarget = iframe.querySelector(SELECTORS.WIDGET);

      if (localTarget) {
        scrollToIframe(localTarget);
        internalObserver.disconnect();
      }
    });

    const mainObserver = new MutationObserver(function () {
      const isReady = iframe.hasAttribute('vce-ready');
      const directoryApp = iframe.querySelector(SELECTORS.DIRECTORY_APP);

      if (isReady && directoryApp) {
        internalObserver.observe(directoryApp, { childList: true });
        mainObserver.disconnect();
      }
    });

    mainObserver.observe(iframe, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      mainObserver.disconnect();
      internalObserver.disconnect();
    };
  }, [router.events]);

  useEffect(() => {
    return () => {
      const iframes = document.querySelectorAll(
        'iframe[name^="dr_tracking_frame"]'
      );

      [...iframes].forEach((iframe) => {
        iframe.parentNode.removeChild(iframe);
      });
    };
  }, []);

  return (
    <section
      className={`${styles.wrapper} ${className}`}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Widget"
      data-design-module_name="think NEXPLANON may be right for you?"
      id={WIDGET_WRAPPER_ID}
      {...props}
    >
      <Script id="directory-widget" src={FINDER_SCRIPT} />
      <directory-widget partner="290" ref={iframeRef} />
    </section>
  );
};

const MemoFinder = memo(Finder);

export { MemoFinder as Finder };
