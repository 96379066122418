import React from 'react';
import Layout from 'Nori/Layout';
import Button from 'Nori/Button';
import Image from 'Nori/Image';
import SplitContent from 'Custom/Split/SplitContent';
import AjustableImage from 'Custom/AjustableImage';
import RichText from 'Custom/RichText';
import Hero from 'Custom/Hero';
import Container from 'Nori/Container';
import FAQ from 'Custom/FAQ';
import FAQHeader from 'Custom/FAQ/FAQHeader';
import { Finder } from 'Custom/Finder';
import { NAV_UPDATES_JOB_CODE } from 'Custom/Footer/Footer.utils.js';

import { FAQ_HOME_QUESTIONS } from 'Utils/questions';
import HAND_IMAGE from 'Images/misc/hand.webp';
import HERO_WOMAN_IMAGE from 'Images/people/home-hero-desktop.webp';
import HERO_WOMAN_IMAGE_MOBILE from 'Images/people/home-hero-mobile.webp';
import EFFECTIVE_IMAGE from 'Images/misc/effective.webp';
import CIRCLE_IMAGE from 'Images/misc/circle-arrows-yellow.webp';
import HAND_SHORT_IMAGE from 'Images/misc/hand-shadow-short.webp';
import PLUS_IMAGE from 'Images/misc/plus-gold.webp';
import ArrowDown from 'Images/brand-icons/arrow-down.svg';

import styles from 'styles/pages/home.module.scss';

const HEAD = {
  title: 'NEXPLANON® (etonogestrel implant) 68 mg Radiopaque ǀ Official Site',
  description:
    'Find information about NEXPLANON® (etonogestrel implant) 68 mg Radiopaque, including effectiveness, how it works, FAQs, and side effects.',
  keywords: 'nexplanon',
};

const HERO_IMAGE = {
  src: HERO_WOMAN_IMAGE,
  width: HERO_WOMAN_IMAGE.width / 1.5,
  height: HERO_WOMAN_IMAGE.height / 1.5,
};

const HERO_IMAGE_MOBILE = {
  src: HERO_WOMAN_IMAGE_MOBILE,
  width: HERO_WOMAN_IMAGE_MOBILE.width / 1.5,
  height: HERO_WOMAN_IMAGE_MOBILE.height / 1.5,
};

export default function Home() {
  return (
    <Layout head={HEAD} jobCode={NAV_UPDATES_JOB_CODE}>
      <Hero
        title={<span className="semibold">MEET NEXPLANON</span>}
        background="primaryLight"
        variant="main"
        image={HERO_IMAGE}
        mobileImage={HERO_IMAGE_MOBILE}
        imageBreakpoint="lg"
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Hero"
        data-design-module_name="MEET NEXPLANON"
        data-design-module_no="01"
      >
        <h2>
          the birth control implant that goes <br />
          <span>in your arm</span>
        </h2>
        <Button
          href="/what-is-nexplanon/"
          data-design-type="clickAction"
          data-design-category="button_vd"
          data-design-action="Button"
          data-design-label="How NEXPLANON Works"
          data-design-nori_id="Hero"
          data-design-module_name="MEET NEXPLANON"
          data-design-module_no="01"
        >
          How NEXPLANON Works
        </Button>
        <h3>
          already on <strong>NEXPLANON</strong>?
        </h3>
        <p>
          Learn what comes next and register your NEXPLANON for resources and
          reminders.
        </p>

        <Button
          type="outlineSecondary"
          size="small"
          href="/enrollment/"
          data-design-type="clickAction"
          data-design-category="button_vd"
          data-design-action="Button"
          data-design-label="Register My NEXPLANON"
          data-design-nori_id="Hero"
          data-design-module_name="MEET NEXPLANON"
          data-design-module_no="01"
        >
          Register My NEXPLANON
        </Button>

        <div className={styles.moreInformation}>
          <Button
            type="simple"
            size="small"
            href="#nexplanon-works-preventing-ovulation"
            data-design-type="clickAction"
            data-design-category="content_link_vd"
            data-design-action="Link-Anchor"
            data-design-label="More Information"
            data-design-nori_id="Hero"
            data-design-module_name="MEET NEXPLANON"
            data-design-module_no="01"
          >
            <ArrowDown /> More Information
          </Button>
        </div>
      </Hero>

      <SplitContent
        id="nexplanon-works-preventing-ovulation"
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Inline Content"
        data-design-module_name="NEXPLANON works by preventing ovulation"
        data-design-module_no="02"
      >
        <AjustableImage
          caption="implant not actual size"
          image={{
            src: HAND_IMAGE,
            alt: "Visual of the Size of an Implant Relative to a Person's Hand",
          }}
        />
        <>
          <RichText>
            <h2>NEXPLANON works by preventing ovulation</h2>
            <p className="intro">
              NEXPLANON works by using a hormone that stops an egg from being
              released by your ovary and prevents sperm from reaching the egg.
              It also changes the lining of your uterus.
            </p>
          </RichText>
          <Button
            href="/what-is-nexplanon/"
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Button"
            data-design-label="Show Me How It Works"
            data-design-nori_id="Inline Content"
            data-design-module_name="NEXPLANON works by preventing ovulation"
            data-design-module_no="02"
          >
            Show Me How It Works
          </Button>
        </>
      </SplitContent>

      <SplitContent
        reverse
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Inline Content"
        data-design-module_name="NEXPLANON is just as effective as the pill - without the daily hassle"
        data-design-module_no="03"
      >
        <AjustableImage
          size="xl"
          background="secondary2Light"
          image={{
            src: EFFECTIVE_IMAGE,
            alt: 'Over 99% Effective: Less Than 1 Pregnancy per 100 Women Who Used NEXPLANON® (etonogestrel implant) 68 mg Radiopaque for 1 Year',
          }}
          specialImage={true}
        />
        <>
          <RichText>
            <h2>
              NEXPLANON is just as effective as the pill - without the daily
              hassle
            </h2>
            <p className="intro">
              This is birth control you don’t have to take every day. The small,
              flexible implant prevents pregnancy for up to 3 years.*
            </p>
            <p>*NEXPLANON must be removed by the end of the third year.</p>
          </RichText>
          <Button
            type="secondary"
            href="/effectiveness/"
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Button"
            data-design-label="Get the Details"
            data-design-nori_id="Inline Content"
            data-design-module_name="NEXPLANON is just as effective as the pill - without the daily hassle"
            data-design-module_no="03"
          >
            Get the Details
          </Button>
        </>
      </SplitContent>

      <SplitContent
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Inline Content"
        data-design-module_name="it's reversible"
        data-design-module_no="04"
      >
        <AjustableImage
          background="secondary4Light"
          image={{ src: CIRCLE_IMAGE }}
        />
        <>
          <RichText>
            <h2>it's reversible</h2>
            <p className="intro">
              NEXPLANON is reversible. If your plans change, it can be removed
              by a trained healthcare professional at any time during the 3-year
              period.
            </p>
          </RichText>
          <Button
            type="secondary4"
            href="/removal/"
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Button"
            data-design-label="Learn More About Removal"
            data-design-nori_id="Inline Content"
            data-design-module_name="it's reversible"
            data-design-module_no="04"
          >
            Learn More About Removal
          </Button>
        </>
      </SplitContent>

      <SplitContent
        reverse
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Inline Content"
        data-design-module_name="side effects of NEXPLANON"
        data-design-module_no="05"
      >
        <AjustableImage image={{ src: PLUS_IMAGE }} />
        <>
          <RichText>
            <h2>side effects of NEXPLANON</h2>
            <p className="intro">
              The most common side effect of NEXPLANON is a change in your
              normal menstrual bleeding pattern. In studies, 1 in 10 women
              stopped using NEXPLANON because of an unfavorable change in their
              bleeding pattern. Besides changes in menstrual bleeding patterns,
              other frequent side effects that caused women to stop using the
              implant include mood swings, weight gain, headache, acne, and
              depressed mood.
            </p>
          </RichText>
          <Button
            href="/side-effects/"
            data-design-type="clickAction"
            data-design-category="button_vd"
            data-design-action="Button"
            data-design-label=" Side Effects of NEXPLANON"
            data-design-nori_id="Inline Content"
            data-design-module_name="side effects of NEXPLANON"
            data-design-module_no="05"
          >
            Side Effects of NEXPLANON
          </Button>
        </>
      </SplitContent>

      <section
        className={styles.splitAccordion}
        data-design-visible="visible_vd"
        data-design-type="module"
        data-design-nori_id="Accordion"
        data-design-module_name="questions to talk through with your doctor"
        data-design-module_no="06"
      >
        <Container>
          <div className={styles.splitBlock}>
            <div className={styles.content}>
              <RichText>
                <h2>questions to talk through with your doctor</h2>

                <p className="intro">
                  Talk to your doctor about the risks and benefits of NEXPLANON.
                </p>
              </RichText>
              <div className={styles.ctaWrapper}>
                <Button
                  type="outlineSecondary2"
                  href="/questions"
                  data-design-type="clickAction"
                  data-design-category="button_vd"
                  data-design-action="Button"
                  data-design-label="View All FAQs"
                  data-design-nori_id="Accordion"
                  data-design-module_name="questions to talk through with your doctor"
                  data-design-module_no="06"
                >
                  View All FAQs
                </Button>
                <Button
                  type="primary"
                  target="_blank"
                  href="/assets/static/Nexplanon-FAQ.pdf"
                  data-design-type="clickAction"
                  data-design-category="button_vd"
                  data-design-action="Download"
                  data-design-label="Download Questions"
                  data-design-nori_id="Accordion"
                  data-design-module_name="questions to talk through with your doctor"
                  data-design-module_no="06"
                >
                  Download Questions
                </Button>
              </div>
            </div>
            <div className={styles.accordion}>
              <FAQ allowMultiple={true}>
                {FAQ_HOME_QUESTIONS.map((item, index) => (
                  <React.Fragment key={`question-${index}`}>
                    <FAQHeader
                      data-design-type="clickAction"
                      data-design-label={item.title}
                      data-design-nori_id="Accordion"
                      data-design-module_name="questions to talk through with your doctor"
                      data-design-module_no="06"
                      data-design-element_no={`${
                        index < 9 ? '0' + (index + 1) : index + 1
                      }`}
                    >
                      {item.title}
                    </FAQHeader>
                    <div className={styles.faqContent}>{item.content}</div>
                  </React.Fragment>
                ))}
              </FAQ>
            </div>
            <p className={styles.caption}>Implant not actual size</p>
            <Image
              className={styles.handImage}
              src={HAND_SHORT_IMAGE}
              width={328}
              height={343}
              alt=""
            />
          </div>
        </Container>
      </section>

      <Finder id="doctortemp" data-design-module_no="07" />
    </Layout>
  );
}
