import React from 'react';
import Image from 'Nori/Image';
import styles from 'Custom/AjustableImage/index.module.scss';
import EffectiveImage from 'Images/misc/effective-mobile.webp';
import ItemBreakpoint from 'Custom/ItemBreakpoint/index';

const IMAGE_SIZES = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
  xl: styles.xl,
  default: styles.medium,
};

const CAPTION_SIZES = {
  small: styles.small,
  medium: styles.medium,
  default: styles.medium,
};

const IMAGE_BACKGROUNDS = {
  primaryLight: styles.primaryLight,
  secondary2Light: styles.secondary2Light,
  secondary3Light: styles.secondary3Light,
  secondary4Light: styles.secondary4Light,
  default: styles.primaryLight,
};

const AjustableImage = ({
  caption = '',
  className = '',
  background = '',
  captionSize = '',
  size = '',
  image = {},
  imageMobile = null,
  specialImage = false,
  children,
  ...props
}) => {
  const bgClassName =
    IMAGE_BACKGROUNDS[background] || IMAGE_BACKGROUNDS.default;
  const sizeClassName = IMAGE_SIZES[size] || IMAGE_SIZES.default;
  const captionSizeClassName =
    CAPTION_SIZES[captionSize] || CAPTION_SIZES.default;

  let imageItem = <Image {...image} />;
  if (specialImage) {
    imageItem = (
      <ItemBreakpoint>
        <Image {...image} />
        <Image
          className={styles.effectiveMobile}
          src={EffectiveImage}
          alt="Over 99% Effective: Less Than 1 Pregnancy per 100 Women Who Used NEXPLANON® (etonogestrel implant) 68 mg Radiopaque for 1 Year"
        />
      </ItemBreakpoint>
    );
  } else if (imageMobile) {
    imageItem = (
      <ItemBreakpoint className={styles.ajustableBreakpoint}>
        <Image {...image} />
        <Image {...imageMobile} />
      </ItemBreakpoint>
    );
  }

  return (
    <figure
      className={`${styles.ajustableImage} ${bgClassName} ${sizeClassName} ${className}`}
      {...props}
    >
      {caption && (
        <figcaption className={captionSizeClassName}>{caption}</figcaption>
      )}
      <div className={styles.ajustableItem}>{imageItem}</div>
    </figure>
  );
};

export default AjustableImage;
