import React, { Children } from 'react';
import Container from 'Nori/Container';
import styles from 'Custom/Split/SplitContent.module.scss';

const LEFT_SPLIT_SIDE = 0;
const RIGHT_SPLIT_SIDE = 1;

const SPLIT_VARIANTS = {
  questions: styles.questions,
  default: '',
};

const SplitContent = ({
  className = '',
  variant = 'default',
  reverse = false,
  children,
  ...props
}) => {
  const childrenArray = Children.toArray(children);
  const leftSide = childrenArray[LEFT_SPLIT_SIDE];
  const rightSide = childrenArray[RIGHT_SPLIT_SIDE];
  const directionClassName = reverse ? styles.ltr : styles.rtl;

  const variantClassName = SPLIT_VARIANTS[variant] || SPLIT_VARIANTS.default;

  return (
    <div
      className={`${styles.splitContentWrapper} ${directionClassName} ${variantClassName} ${className}`}
      {...props}
    >
      <Container>
        <div className={styles.splitRow}>
          {leftSide && <div className={styles.splitLeft}>{leftSide}</div>}
          {rightSide && (
            <div className={styles.splitRight}>
              <div className={styles.splitRightContent}>{rightSide}</div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SplitContent;
