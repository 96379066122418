import React from 'react';
import styles from 'Custom/FAQ/FAQHeader.module.scss';
import AngleDown from 'Images/brand-icons/angle-down.svg';

const FAQHeader = ({ isExpanded, children, ...props }) => {
  return (
    <div className={`${styles.faqHeader}`}>
      <button
        className={styles.accordionButton}
        {...props}
        aria-expanded={isExpanded}
        data-design-category="accordion_vd"
        data-design-action={isExpanded ? 'Close' : 'Expand'}
      >
        <span className={styles.faqItemTitle}>{children}</span>
        {<AngleDown className={isExpanded ? styles.opened : styles.closed} />}
      </button>
    </div>
  );
};

export default FAQHeader;
